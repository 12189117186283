
import React from "react";
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer has-cards">
          <Container>
          
              <Col lg="6">
                <h4 className="mb-0 font-weight-light">
                  <font color="#2F8EAF">SERVICES</font>:
                  <br></br>
                  <li>Strategic Planning</li>
                  <li>Financial Modeling and Analysis</li>
                  <li>Business Plan Development</li>
                  <li>International Project Finance Planning</li>
                  <li>Organic Business Initiative Development</li>

                  <br></br>
                  <font color="#2F8EAF">INDUSTRY SECTORS</font>:
                  <br></br>
                  <li>Media</li>
                  <li>Entertainment</li>
                  <li>Music Publishing</li>
                  <li>Communications</li>
                  <li>Technology</li>

                  <br></br>
                  <font color="#2F8EAF">PRINCIPAL</font>:

                  Frank Correa, Esq.

                </h4>
              </Col>
              
     
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="https://www.correagroupllc.com?ref=adsr-footer"
                    target="_blank"
                  >
                    CORREA GROUP, LLC
                  </a>
                </div>
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href="https://www.correagroupllc.com?ref=adsr-footer"
                      target="_blank"
                    >
                      CORREA GROUP, LLC
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
